"use client"
import styled from "@emotion/styled"

const StyledWrapper = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    > div {
      flex-shrink: 0;

      &:first-of-type {
        flex-shrink: 1;
        flex-grow: 1;
      }
    }
  }
`
const HeroWrapper = ({ children }: { children: any }) => {
  return <StyledWrapper>{children}</StyledWrapper>
}

export default HeroWrapper
