"use client"
import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import Link from "next/link"
import { Desktop, LeftArrow, Mobile, NewText, RightArrow } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors } from "ui/theme/colors"
import { extractImage, extractProperty, getNonUmbracoUrl } from "utils"

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding: 8px 16px;
  min-height: 88px;

  a {
    text-decoration-color: #2768a3;
  }
  svg {
    margin-top: 4px;
    path {
      fill: #2768a3;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 100px;
    padding: 16px;
  }
`

const StyledInnerDiv = styled.div`
  gap: 16px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 8px;
    align-items: flex-start;
    flex-direction: column;
  }
`

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: flex-end;
    width: 100%;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  flex: 1;
`

type Props = {
  item: any
  style?: any
}

function InternationalMapItem({ item, style }: Props) {
  const title = extractProperty(item, "title")
  const linkText = extractProperty(item, "linkText")

  const link = extractProperty(item, "link")
  const locale = useLocale()

  const icon = extractImage(item, "icon")

  return (
    <StyledDiv style={style}>
      <StyledInnerDiv>
        <ImageWithAlt
          width={48}
          height={48}
          image={icon}
          style={{ objectFit: "contain" }}
        />

        <StyledWrapper>
          <NewText
            component={"p"}
            desktopFontSize={24}
            desktopLineHeight={"1.2"}
            desktopFontWeight={400}
            mobileFontSize={18}
            mobileLineHeight={"1.2"}
            color={sharedColors.black}
          >
            {title}
          </NewText>
          <Link
            href={getNonUmbracoUrl(link[0]?.url)}
            target={getNonUmbracoUrl(link[0]?.target)}
          >
            <StyledTextWrapper>
              <NewText
                component={"p"}
                desktopFontSize={18}
                desktopLineHeight={"1.2"}
                desktopFontWeight={400}
                mobileFontSize={16}
                mobileLineHeight={"1.2"}
                color={"#2768A3"}
                maxWidth={"200px"}
              >
                {linkText}
              </NewText>
              <Desktop>
                {locale === "he" ? <LeftArrow /> : <RightArrow />}
              </Desktop>
            </StyledTextWrapper>
          </Link>
        </StyledWrapper>
        <Mobile>
          <Link
            href={getNonUmbracoUrl(link[0]?.url)}
            target={getNonUmbracoUrl(link[0]?.target)}
          >
            {locale === "he" ? <LeftArrow /> : <RightArrow />}
          </Link>
        </Mobile>
      </StyledInnerDiv>
    </StyledDiv>
  )
}

export default InternationalMapItem
