"use client"
import styled from "@emotion/styled"

import { IconButton, css } from "@mui/material"
import { useState } from "react"
import { Autoplay, EffectFade } from "swiper"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  Desktop,
  LeftArrow,
  RightArrow,
  StartIcon,
  StopSign,
  transitionTime,
  useIsDesktop,
} from "ui"
import { sharedColors } from "ui/theme/colors"
import { Culture } from "utils"
import ProjectSlide from "./ProjectSlide"

const StyledContainer = styled.div<{ cssString?: string }>`
  position: relative;
  margin-top: 24px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
  }
  ${(prop) => css(prop.cssString)}
`

const StyledPaginatorContainer = styled.div`
  position: absolute;
  top: 19px;
  left: 16px;
  z-index: 10;
  background-color: white;
  padding: 8px;
  border-radius: 200px;
  ${(props) => props.theme.breakpoints.up("md")} {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 0;
    background-color: unset;
  }
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
`

const StyledBullet = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 3.335px;
  cursor: pointer;
  border: ${(props) =>
    props.active ? "1.67px solid #2768A3" : "1.67px solid black"};
  background-color: ${(props) => (props.active ? "#2768A3" : "none")};
`

const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  flex-direction: column;
  > div {
    position: relative;
    flex-grow: 1;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    > div {
      position: relative;
      flex-grow: 1;
    }
  }
`

const StyledPlayButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 4.923px;
  border-radius: 123.077px;
  background-color: ${sharedColors.white};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 16px;
  top: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    right: 24px;
    top: 24px;
  }

  cursor: pointer;
  z-index: 30;
`

type Props = {
  slides: any
  title: string
  culture: Culture
  cssString?: string
  defaultPlaying?: boolean
}
function SliderComponent({
  slides,
  title,
  culture,
  cssString,
  defaultPlaying = true,
}: Props) {
  const isDesktop = useIsDesktop()
  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [isPlaying, setIsPlaying] = useState<boolean>(defaultPlaying && true)

  const toggleAutoplay = () => {
    if (swiperRef) {
      if (isPlaying) {
        swiperRef.autoplay.stop()
      } else {
        swiperRef.autoplay.start()
      }
      setIsPlaying(!isPlaying)
    }
  }
  return (
    <StyledContainer cssString={cssString}>
      <Swiper
        onSwiper={setSwiperRef}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex)
        }}
        style={{
          padding: 0,
          borderRadius: 16,
        }}
        speed={transitionTime * 1000}
        modules={[EffectFade, Autoplay]}
        effect="fade"
        autoplay={
          isPlaying
            ? {
                delay: 4000,
                disableOnInteraction: false,
              }
            : false
        }
      >
        {slides.map((item: any, key: number) => (
          <StyledSwiperSlide
            key={key.toString()}
            style={{
              width: "100%",
              height: isDesktop ? "670px" : "560px",
            }}
          >
            <ProjectSlide
              activeIndex={activeIndex}
              culture={culture}
              item={item}
              slides={slides}
              swiperRef={swiperRef}
              title={title}
            />
          </StyledSwiperSlide>
        ))}
      </Swiper>
      {!isDesktop && (
        <StyledPaginatorContainer>
          <Desktop>
            <IconButton
              onClick={() => {
                swiperRef.slidePrev()
              }}
            >
              {culture === "he-il" ? <RightArrow /> : <LeftArrow />}
            </IconButton>
          </Desktop>
          <StyledBulletsContainer>
            {slides.map((_: any, index: number) => (
              <StyledBullet
                key={index.toString()}
                onClick={() => {
                  swiperRef.slideTo(index)
                }}
                active={index === activeIndex}
              />
            ))}
          </StyledBulletsContainer>
          <Desktop>
            <IconButton
              onClick={() => {
                swiperRef.slideNext()
              }}
            >
              {culture === "he-il" ? <LeftArrow /> : <RightArrow />}
            </IconButton>
          </Desktop>
        </StyledPaginatorContainer>
      )}
      <StyledPlayButton
        onClick={toggleAutoplay}
        aria-label={isPlaying ? "stop-playing-button" : "start-playing-button"}
      >
        {isPlaying ? <StopSign /> : <StartIcon />}
      </StyledPlayButton>
    </StyledContainer>
  )
}
export default SliderComponent
