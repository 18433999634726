"use client"
import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import { useInView } from "react-intersection-observer"
import {
  Container,
  NewText,
  TransitionUpComponent,
  useInViewConfig,
  useTransitionUpStyles,
} from "ui"
import { sharedColors } from "ui/theme/colors"
import { delayTime, styleObjectToCss } from "ui/transitions/utils"
import { extractProperty } from "utils"
import InternationalMapItem from "./InternationalMapItem"

const StyledDiv = styled.div`
  width: 100%;
  padding-top: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 60px;
  }
`

const StyledVideoContainer = styled.div`
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000917;
  height: 100%;
  min-height: 589px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 100%;
    min-height: 450px;
    overflow: hidden;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    > div {
      width: 100%;
      height: 100%;
    }
  }
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  ${(props) => props.theme.breakpoints.up("md")} {
    inset: 0;
  }
`

const StyledBackDrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  background: linear-gradient(90.11deg, rgba(6, 99, 173, 0) -50.78%, rgba(6, 99, 173, 0.88) 51.08%);

  background-blend-mode: overlay;
  ${(props) => props.theme.breakpoints.up("md")} {
    background: linear-gradient(89.99deg, rgba(6, 99, 173, 0) 5.63%, rgba(6, 99, 173, 0.8) 69.41%);
  }
`

const StyledContainer = styled(Container)`
  inset: 0;
  padding: 32px 16px 32px 16px;
  z-index: 10;
  ${(props) => props.theme.breakpoints.down("sm")} {
    h2,
    h3 {
      max-width: 280px;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 60px 0px 60px 0px;
  }
`

const StyledInnerDiv = styled.div`
  margin-top: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
    width: 80%;
  }
`

const StyledBlockDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

type Props = {
  data: any
}

function InternationalMap({ data }: Props) {
  const blockTitle = extractProperty(data, "blockTitle")

  const subTitle = extractProperty(data, "subTitle")
  const innerBlock = extractProperty(data, "innerBlock")

  const { ref, inView } = useInView(useInViewConfig)
  const { ref: mapRef, inView: mapInView } = useInView(useInViewConfig)

  const titleTransitionStyles = useTransitionUpStyles(inView, delayTime)
  const subtitleTransitionStyles = useTransitionUpStyles(inView, delayTime * 1.5)

  const locale = useLocale()

  return (
    <StyledDiv ref={ref}>
      <StyledVideoContainer>
        <StyledBackDrop />
        <StyledVideo
          autoPlay
          loop
          muted={true}
          playsInline
          style={{ transform: locale === "he" ? "none" : "rotate(-180deg)" }}
        >
          <source src={"/images/internationVideo.mp4"} type="video/mp4" />
        </StyledVideo>
        <StyledContainer>
          <NewText
            component={"h2"}
            desktopFontSize={40}
            desktopLineHeight={"1.2"}
            desktopFontWeight={400}
            mobileFontSize={32}
            mobileLineHeight={"1.3"}
            color={sharedColors.white}
            cssString={styleObjectToCss(titleTransitionStyles)}
          >
            {blockTitle}
          </NewText>
          <NewText
            component={"h3"}
            desktopFontSize={24}
            desktopLineHeight={"1.3"}
            desktopFontWeight={400}
            mobileFontSize={18}
            mobileLineHeight={"1.3"}
            color={sharedColors.white}
            maxWidth={"600px"}
            marginTop={"8px"}
            cssString={styleObjectToCss(subtitleTransitionStyles)}
          >
            {subTitle}
          </NewText>
          <StyledInnerDiv>
            <StyledBlockDiv ref={mapRef}>
              {innerBlock.map((item: any, index: number) => (
                <TransitionUpComponent inView={mapInView} delay={0} key={index}>
                  {(transitionStyles) => (
                    <InternationalMapItem key={index} item={item} style={{ ...transitionStyles }} />
                  )}
                </TransitionUpComponent>
              ))}
            </StyledBlockDiv>
          </StyledInnerDiv>
        </StyledContainer>
      </StyledVideoContainer>
    </StyledDiv>
  )
}

export default InternationalMap
